import queryString from 'query-string'
import { get, patch, ERROR_ABORT_CONTROLLER } from '../../utils'
import {
  API_LIST_CAMERA,
  API_LIST_VEHICLE_TYPE,
  API_DESTINATION,
  API_LIST_PURPOSE,
  API_LIST_TODAY_VISITOR,
  API_UPDATE_VISITOR,
  API_LIST_PLATE_ORIGIN,
  API_LIST_PLATE_COLOR,
} from '../api-constraint'

export const LOAD_ALL_OPTION_DROPDOWN_REQUEST = 'LOAD_ALL_OPTION_DROPDOWN_REQUEST'
export const LOAD_ALL_OPTION_DROPDOWN_SUCCESS = 'LOAD_ALL_OPTION_DROPDOWN_SUCCESS'
export const LOAD_ALL_OPTION_DROPDOWN_FAILURE = 'LOAD_ALL_OPTION_DROPDOWN_FAILURE'
export const LOAD_ALL_OPTION_DROPDOWN_IDLE = 'LOAD_ALL_OPTION_DROPDOWN_IDLE'

export const loadAllOptionDropdownRequest = () => {
  return {
    type: LOAD_ALL_OPTION_DROPDOWN_REQUEST,
  }
}

export const loadAllOptionDropdownSuccess = ({
  listCamera,
  listVehicleType,
  listDestination,
  listAllDestination,
  listPurposeVisit,
  listPlateOrigin,
  listPlateColor,
}) => {
  return {
    type: LOAD_ALL_OPTION_DROPDOWN_SUCCESS,
    payload: {
      listCamera,
      listVehicleType,
      listDestination,
      listAllDestination,
      listPurposeVisit,
      listPlateOrigin,
      listPlateColor,
    },
  }
}

export const loadAllOptionDropdownFailure = error => {
  return {
    type: LOAD_ALL_OPTION_DROPDOWN_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadAllOptionDropdownIdle = () => {
  return {
    type: LOAD_ALL_OPTION_DROPDOWN_IDLE,
  }
}

export const loadAllOptionDropdown = (options = {}) => {
  return async dispatch => {
    const { cookie, cameraTypes } = options
    dispatch(loadAllOptionDropdownRequest())
    try {
      const queryAllDestination = queryString.stringify({ all: true })
      const queryText = cameraTypes ? `?${queryString.stringify({ cameraTypes })}` : ''
      const [listCamera, listVehicleType, listDestination, listAllDestination, listPurposeVisit, listPlateOrigin, listPlateColor] = await Promise.all(
        [
          get({
            path: `${API_LIST_CAMERA}${queryText}`,
            headers: cookie ? { cookie } : undefined,
          }),
          get({
            path: `${API_LIST_VEHICLE_TYPE}`,
            headers: cookie ? { cookie } : undefined,
          }),
          get({
            path: `${API_DESTINATION}`,
            headers: cookie ? { cookie } : undefined,
          }),
          get({
            path: `${API_DESTINATION}?${queryAllDestination}`,
            headers: cookie ? { cookie } : undefined,
          }),
          get({
            path: `${API_LIST_PURPOSE}`,
            headers: cookie ? { cookie } : undefined,
          }),
          get({
            path: `${API_LIST_PLATE_ORIGIN}`,
            headers: cookie ? { cookie } : undefined,
          }),
          get({
            path: `${API_LIST_PLATE_COLOR}`,
            headers: cookie ? { cookie } : undefined,
          }),
        ],
      )

      dispatch(
        loadAllOptionDropdownSuccess({
          listCamera,
          listVehicleType,
          listDestination,
          listAllDestination,
          listPurposeVisit,
          listPlateOrigin,
          listPlateColor,
        }),
      )
    } catch (err) {
      dispatch(loadAllOptionDropdownFailure(err))
    } finally {
      dispatch(loadAllOptionDropdownIdle())
    }
  }
}

export const LOAD_LIST_TODAY_VISITOR_REQUEST = 'LOAD_LIST_TODAY_VISITOR_REQUEST'
export const LOAD_LIST_TODAY_VISITOR_SUCCESS = 'LOAD_LIST_TODAY_VISITOR_SUCCESS'
export const LOAD_LIST_TODAY_VISITOR_FAILURE = 'LOAD_LIST_TODAY_VISITOR_FAILURE'
export const LOAD_LIST_TODAY_VISITOR_IDLE = 'LOAD_LIST_TODAY_VISITOR_IDLE'

export const loadListTodayVisitorRequest = camera => {
  return {
    type: LOAD_LIST_TODAY_VISITOR_REQUEST,
    payload: {
      camera,
    },
  }
}

export const loadListTodayVisitorSuccess = listTodayVisitor => {
  return {
    type: LOAD_LIST_TODAY_VISITOR_SUCCESS,
    payload: {
      listTodayVisitor,
    },
  }
}

export const loadListTodayVisitorFailure = error => {
  return {
    type: LOAD_LIST_TODAY_VISITOR_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadListTodayVisitorIdle = () => {
  return {
    type: LOAD_LIST_TODAY_VISITOR_IDLE,
  }
}

export const loadListTodayVisitor = (options = {}) => {
  return async dispatch => {
    const { cookie, camera, date, signal } = options
    dispatch(loadListTodayVisitorRequest(camera))
    try {
      const query = queryString.stringify({ date })
      const listTodayVisitor = await get({
        path: `${API_LIST_TODAY_VISITOR}/${camera}?${query}`,
        headers: cookie ? { cookie } : undefined,
        signal,
      })
      dispatch(loadListTodayVisitorSuccess(listTodayVisitor))
      dispatch(loadListTodayVisitorIdle())
    } catch (err) {
      if (err.name === ERROR_ABORT_CONTROLLER) {
        dispatch(loadListTodayVisitorRequest(camera))
      } else {
        dispatch(loadListTodayVisitorFailure(err))
        dispatch(loadListTodayVisitorIdle())
      }
    }
  }
}

export const LOAD_LIST_TODAY_VISITOR_PAGINATION_REQUEST = 'LOAD_LIST_TODAY_VISITOR_PAGINATION_REQUEST'
export const LOAD_LIST_TODAY_VISITOR_PAGINATION_SUCCESS = 'LOAD_LIST_TODAY_VISITOR_PAGINATION_SUCCESS'
export const LOAD_LIST_TODAY_VISITOR_PAGINATION_FAILURE = 'LOAD_LIST_TODAY_VISITOR_PAGINATION_FAILURE'
export const LOAD_LIST_TODAY_VISITOR_PAGINATION_IDLE = 'LOAD_LIST_TODAY_VISITOR_PAGINATION_IDLE'

export const loadListTodayVisitorPaginationRequest = () => {
  return {
    type: LOAD_LIST_TODAY_VISITOR_PAGINATION_REQUEST,
  }
}

export const loadListTodayVisitorPaginationSuccess = nextListTodayVisitor => {
  return {
    type: LOAD_LIST_TODAY_VISITOR_PAGINATION_SUCCESS,
    payload: {
      nextListTodayVisitor,
    },
  }
}

export const loadListTodayVisitorPaginationFailure = error => {
  return {
    type: LOAD_LIST_TODAY_VISITOR_PAGINATION_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadListTodayVisitorPaginationIdle = () => {
  return {
    type: LOAD_LIST_TODAY_VISITOR_PAGINATION_IDLE,
  }
}

export const loadListTodayVisitorPagination = ({ camera, date, paginationDate, signal }) => {
  return async dispatch => {
    dispatch(loadListTodayVisitorPaginationRequest())
    try {
      const query = queryString.stringify({ date, paginationDate })
      const nextListTodayVisitor = await get({
        path: `${API_LIST_TODAY_VISITOR}/${camera}?${query}`,
        signal,
      })
      dispatch(loadListTodayVisitorPaginationSuccess(nextListTodayVisitor))
    } catch (err) {
      dispatch(loadListTodayVisitorPaginationFailure(err))
    } finally {
      dispatch(loadListTodayVisitorPaginationIdle())
    }
  }
}

export const UPDATE_VISITOR_DETECTION_REQUEST = 'UPDATE_VISITOR_DETECTION_REQUEST'
export const UPDATE_VISITOR_DETECTION_SUCCESS = 'UPDATE_VISITOR_DETECTION_SUCCESS'
export const UPDATE_VISITOR_DETECTION_FAILURE = 'UPDATE_VISITOR_DETECTION_FAILURE'
export const UPDATE_VISITOR_DETECTION_IDLE = 'UPDATE_VISITOR_DETECTION_IDLE'
export const CHECK_LICENSE_PLATE_MEMBER = 'CHECK_LICENSE_PLATE_MEMBER'

export const updateVisitorDetectionRequest = () => {
  return {
    type: UPDATE_VISITOR_DETECTION_REQUEST,
  }
}

export const updateVisitorDetectionSuccess = updatedVisitorDetectionData => {
  return {
    type: UPDATE_VISITOR_DETECTION_SUCCESS,
    payload: {
      updatedVisitorDetectionData,
    },
  }
}

export const updateVisitorDetectionFailure = error => {
  return {
    type: UPDATE_VISITOR_DETECTION_FAILURE,
    payload: {
      error,
    },
  }
}

export const updateVisitorDetectionIdle = () => {
  return {
    type: UPDATE_VISITOR_DETECTION_IDLE,
  }
}

export const updateVisitorDetection = data => {
  return async dispatch => {
    dispatch(updateVisitorDetectionRequest())
    try {
      const updated = await patch({
        path: `${API_UPDATE_VISITOR}/${data.id}`,
        body: data,
      })
      dispatch(updateVisitorDetectionSuccess(updated))
    } catch (err) {
      dispatch(updateVisitorDetectionFailure(err))
    } finally {
      dispatch(updateVisitorDetectionIdle())
    }
  }
}
