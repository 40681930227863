export const CONNECT_SOCKET = 'CONNECT_SOCKET'

export const connectSocket = () => {
  return {
    type: CONNECT_SOCKET,
  }
}

export const SOCKET_CONNECTED = 'SOCKET_CONNECTED'

export const socketConnected = () => {
  return {
    type: SOCKET_CONNECTED,
  }
}

export const SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED'

export const socketDisconnected = () => {
  return {
    type: SOCKET_DISCONNECTED,
  }
}

export const SOCKET_TRAFFIC_DASHBOARD = 'SOCKET_TRAFFIC_DASHBOARD'

export const socketTrafficDashboard = ({
  avgTimeSpentValue,
  greenGasValue,
  maxGreenGasValue,
  latestVehicleData,
  vehicleCountLastHour,
  vehicleCountNumber,
  maxVehicleCountNumber,
  vehicleCountByDestList,
  vehicleCountByTypeList,
  vehicleCountOffPeakData,
  vehicleCountOnPeakData,
  vehicleCountPerHourList,
  vehicleCountGroupByPurposeData,
  vehicleCountGroupByIsMemberData,
  listRankCompanyVehicleMember,
}) => {
  return {
    type: SOCKET_TRAFFIC_DASHBOARD,
    payload: {
      avgTimeSpentValue,
      greenGasValue,
      latestVehicleData: latestVehicleData.data,
      maxGreenGasValue,
      vehicleCountLastHour,
      vehicleCountNumber,
      maxVehicleCountNumber,
      vehicleCountByDestList,
      vehicleCountByTypeList,
      vehicleCountOffPeakData,
      vehicleCountOnPeakData,
      vehicleCountPerHourList,
      vehicleCountGroupByPurposeData,
      vehicleCountGroupByIsMemberData,
      listRankCompanyVehicleMember,
    },
  }
}

export const SOCKET_NOTIFICATION = 'SOCKET_NOTIFICATION'

export const socketNotification = notificationData => {
  return {
    type: SOCKET_NOTIFICATION,
    payload: {
      notificationData,
    },
  }
}

export const SOCKET_IN_COMING_VISITOR = 'SOCKET_IN_COMING_VISITOR'

export const socketInComingVisitor = inComingVisitor => {
  return {
    type: SOCKET_IN_COMING_VISITOR,
    payload: {
      inComingVisitor,
    },
  }
}

export const SOCKET_UPDATE_VISITOR = 'SOCKET_UPDATE_VISITOR'

export const socketUpdateVisitor = updatedVisitorDetectionData => {
  return {
    type: SOCKET_UPDATE_VISITOR,
    payload: {
      updatedVisitorDetectionData,
    },
  }
}

export const SOCKET_INCIDENCE_DATA = 'SOCKET_INCIDENCE_DATA'

export const socketIncidenceData = ({ listIncidenceYearData, totalIncidenceCount, newIncidenceCase }) => {
  return {
    type: SOCKET_INCIDENCE_DATA,
    payload: {
      listIncidenceYearData,
      totalIncidenceCount,
      newIncidenceCase,
    },
  }
}

export const SOCKET_REQUEST_CHECK_LICENSE_PLATE = 'SOCKET_REQUEST_CHECK_LICENSE_PLATE'

export const socketRequestCheckLicensePlate = (licensePlate, province) => {
  return {
    type: SOCKET_REQUEST_CHECK_LICENSE_PLATE,
    payload: {
      licensePlate,
      province,
    },
  }
}

export const SOCKET_RESPONSE_CHECK_LICENSE_PLATE = 'SOCKET_RESPONSE_CHECK_LICENSE_PLATE'

export const socketResponseCheckLicensePlate = member => {
  return {
    type: SOCKET_RESPONSE_CHECK_LICENSE_PLATE,
    payload: {
      member,
    },
  }
}

export const SOCKET_CHECK_LICENSE_PLATE_IDLE = 'SOCKET_CHECK_LICENSE_PLATE_IDLE'

export const socketCheckLicensePlateIdle = () => {
  return {
    type: SOCKET_CHECK_LICENSE_PLATE_IDLE,
  }
}

export const SOCKET_REQUEST_SEARCH_LIST_MEMBERS = 'SOCKET_REQUEST_SEARCH_LIST_MEMBERS'
export const socketRequestSearchListMembers = ({ search, page, order, sortBy, limit }) => {
  return {
    type: SOCKET_REQUEST_SEARCH_LIST_MEMBERS,
    payload: {
      search,
      page,
      order,
      sortBy,
      limit,
    },
  }
}

export const SOCKET_RESPONSE_SEARCH_LIST_MEMBERS = 'SOCKET_RESPONSE_SEARCH_LIST_MEMBERS'
export const socketResponseSearchListMembers = ({ totalItems, totalPages, page, data }) => {
  return {
    type: SOCKET_RESPONSE_SEARCH_LIST_MEMBERS,
    payload: {
      totalItems,
      totalPages,
      page,
      data,
    },
  }
}

export const SOCKET_SEARCH_LIST_MEMBERS_IDLE = 'SOCKET_SEARCH_LIST_MEMBERS_IDLE'
export const socketSearchListMembersIdle = () => {
  return {
    type: SOCKET_SEARCH_LIST_MEMBERS_IDLE,
  }
}

export const SOCKET_REQUEST_SEARCH_LIST_DESTINATION = 'SOCKET_REQUEST_SEARCH_LIST_DESTINATION'
export const socketRequestSearchListDestination = ({ search, page, order, sortBy, limit }) => {
  return {
    type: SOCKET_REQUEST_SEARCH_LIST_DESTINATION,
    payload: {
      search,
      page,
      order,
      sortBy,
      limit,
    },
  }
}

export const SOCKET_RESPONSE_SEARCH_LIST_DESTINATION = 'SOCKET_RESPONSE_SEARCH_LIST_DESTINATION'
export const socketResponseSearchListDestination = ({ totalItems, totalPages, page, data }) => {
  return {
    type: SOCKET_RESPONSE_SEARCH_LIST_DESTINATION,
    payload: {
      totalItems,
      totalPages,
      page,
      data,
    },
  }
}

export const SOCKET_SEARCH_LIST_DESTINATION_IDLE = 'SOCKET_SEARCH_LIST_DESTINATION_IDLE'
export const socketSearchListDestinationIdle = () => {
  return {
    type: SOCKET_SEARCH_LIST_DESTINATION_IDLE,
  }
}

export const SOCKET_UPDATE_SYSTEM_STATUS = 'SOCKET_UPDATE_SYSTEM_STATUS'
export const socketUpdateSystemStatus = systemHealth => {
  return {
    type: SOCKET_UPDATE_SYSTEM_STATUS,
    payload: {
      systemHealth,
    },
  }
}
